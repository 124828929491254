import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TransformingImage from "../components/transforming-image"
import Backlink from "../components/back-link"
import GOLD from "../assets/icons/gold.svg"
import { useStaticQuery, graphql } from "gatsby"

const ChymeiaPage = () => {
  const image = useStaticQuery(graphql`
    query getKunstwerk {
      imageSharp(fluid: { originalName: { eq: "kunstwerk.png" } }) {
        id
        fluid {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Chymeia" />
      <div className="grid grid-cols-12 px-4 lg:px-6 min-h-screen mt-16">
        <div className="col-span-12 lg:col-span-6">
          <h1>Chymeia</h1>
          <h2 className="font-sans">
            ...ist die Wandlung, die Vereinigung von Gegensätzen.
          </h2>
          <p className="flex items-center">
            Aus der dunklen Materia wird das reine Gold{" "}
            <GOLD className="svg w-8 h-8 ml-2 mb-1 text-gold-300" />
          </p>
          <p>
            Das erfordert Mut, Geduld, genaues Hinschauen, Vertrauen und
            Zuversicht. Das reine Gold, der Glanz ist die Lebendigkeit.
          </p>
        </div>
        <div className="col-span-full lg:col-span-6 px-12 lg:px-24 pb-12 lg:pb-0 lg:pt-12">
          <TransformingImage
            image={image.imageSharp.fluid}
            alt={image.imageSharp.fluid.originalName}
          />
        </div>
        <div className="col-span-full flex items-start">
          <Backlink />
        </div>
      </div>
    </Layout>
  )
}

export default ChymeiaPage
