import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"

import { gsap } from "gsap"

const TransformingImage = ({ image }) => {
  const tl = useRef(null)
  const innerCircle = useRef(null)
  const outerCircle = useRef(null)
  const imageRef = useRef(null)

  useEffect(() => {
    tl.current = gsap.timeline({
      paused: true,
    })
    gsap.set(innerCircle.current, {
      transformOrigin: "center center",
    })
    gsap.set(outerCircle.current, {
      transformOrigin: "center center",
    })
    gsap.set(imageRef.current, {
      transformOrigin: "center center",
      scale: 0,
      // clipPath: "circle(0px at center)",
      opacity: 1,
    })

    tl.current.to(innerCircle.current, {
      scale: 3.2,
      duration: 0.4,
      ease: "power2.out",
    })
    tl.current.to(innerCircle.current, {
      scale: 0,
      duration: 0.6,
      ease: "power2.in",
    })
    tl.current.to(
      outerCircle.current,
      {
        scale: 0,
        duration: 0.6,
        ease: "power2.in",
      },
      "-=0.6"
    )
    tl.current.to(innerCircle.current, {
      color: "#be185d",
      scale: 0.5,
      duration: 0.5,
    })
    tl.current.to(innerCircle.current, {
      opacity: 0,
      duration: 0.5,
    })
    tl.current.to(innerCircle.current, {
      opacity: 1,
      duration: 0,
    })
    tl.current.to(innerCircle.current, {
      scale: 3.5,
      duration: 0.4,
      opacity: 0,
      color: "#be185d",
      ease: "power2.out",
    })

    tl.current.to(
      imageRef.current,
      {
        scale: 1,
        // clipPath: "circle(100% at center)",
        duration: 1,
        // opacity: 1,
        ease: "elastic.out(1.5, 1)",
        // ease:
        //   "rough({ template:none.out,strength:1,points:20,taper:'none',randomize: true,clamp: false})",
      },
      "-=0.4"
    )

    return () => {
      if (tl.current) {
        tl.current.kill()
      }
    }
  }, [])

  const playAnimation = () => {
    if (tl.current) {
      tl.current.play()
    }
  }

  return (
    <div
      className="transformingImage relative"
      onClick={playAnimation}
      role="presentation"
    >
      <div className="svg">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64">
          <circle
            cx="32"
            cy="32"
            r="21"
            stroke="currentColor"
            strokeWidth="6"
            ref={outerCircle}
          />
          <circle
            cx="32"
            cy="32"
            r="6"
            fill="currentColor"
            id="inner"
            ref={innerCircle}
          />
        </svg>
      </div>

      <div className="image absolute inset-0 w-full h-auto" ref={imageRef}>
        <Img
          fluid={image}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
          imgStyle={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
      </div>
    </div>
  )
}

export default TransformingImage
